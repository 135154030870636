import {
    SET_REAL_ESTATE_LOADING_ERROR,
    GET_REALESTATE_DATA,
    SUBMIT_REAL_ESTATE_VALUATION,
    SET_MORGAGE_STEP,
    SET_MORGAGE_ESTIMATE_VALUE,
    SET_REAL_ESTATE_VALUATION_STEP,
    SET_SUBMIT_CONTACT_MESSAGE,
    SET_SUBMIT_SERVICE_MESSAGE,
    SET_SERVICE_SUBMITTING,
    SET_SHOW_MODAL,
    SET_SHOW_MODAL_LEGAL,
    SET_MORGAGE_AMOUNT,
    SET_MORGAGE_RESOURCES_AMOUNT,
    SET_MORGAGE_LENGTH,
    SET_REAL_ESTATE_ESTIMATE,
    SET_MAIN_CONTACT_SUBMITTING,
    SET_REAL_ESTATE_PAGINATION,
    GET_REALESTATE_AGENT_TEXTS,
    SET_ASSETS_LOADED
    } from '../../actions/types';
import _ from 'lodash';
/*
Lukáš Berka: SiteContentBerka 
Jakub Hanuš: SiteContentHanus
Lucie Berková: SiteContentBerkova 
Sven Foitischek: SiteContentSven
Tomáš Zikmunda: SiteContentZikmunda
Radek Spáčil: SiteContentSpacil
*/
const INITIAL_STATE = {
    currentRealEstateAgent: 'SiteContentSpacil',
    num1: Math.floor(Math.random() * 11),
    num2: Math.floor(Math.random() * 11),
    showServiceModal: false,
    modalServiceType: 'morgage_estimate',
    showLegalModal: false,
    modalLegalType: 'private',
    morgageEstimateServiceStep: 1,
    realEstateEvaluationStep: 1,

    morgageAmountValue: 300000,
    morgageResourcesValue: 50000,
    morgageLength: 5,
    morgageEstimateValue: 4424,
    realEstateEstimate: 100000,

    assetsAreLoaded: false,

    dispozice: '',
    druh_nemovitosti: '',
    stav_nemovitosti:'',
    typ_vlastnictvi: '',
    velikost: '',

    otherRealEstateInfo:{
        balkon: false,
        bazen: false, 
        developerskeProjekty: false,
        garaz: false,
        parkovaciStani: false,
        terasa: false,
        sklep: false,
        customerMessage: 'Zákazník nenapsal vlastní popis nemovitosti.'
    },
    realEstateAgentTexts: {},

    submittingServiceData: false,
    submitServiceMessage: '',
    submitContactMessage: '',
    submittingMainContactData: false,
    realEstateLoadingError: false,
    realEstateList:[], 
    realEstateCurrentPage: 1,
    
};

const appUIReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        // Displays or closes modal and sets type of service displayed in modal
        case SET_SHOW_MODAL:
            const newModalServiceType =  action.payload.service === '' ? state.modalServiceType : action.payload.service;
            return {...state, showServiceModal: action.payload.show, modalServiceType: newModalServiceType}
        case SET_SHOW_MODAL_LEGAL:
            const newLegalModalServiceType =  action.payload.legalType === '' ? state.modalLegalType : action.payload.legalType;
            return {...state, showLegalModal: action.payload.show, modalLegalType: newLegalModalServiceType}
            
        case SET_REAL_ESTATE_PAGINATION:
            return {...state, realEstateCurrentPage: action.payload}
        case SET_ASSETS_LOADED:
            return {...state, assetsAreLoaded: action.payload}
        case SET_MORGAGE_RESOURCES_AMOUNT:
            return {...state, morgageResourcesValue: action.payload}
        case SET_MORGAGE_AMOUNT:
            return {...state, morgageAmountValue: action.payload}
        case SET_MORGAGE_ESTIMATE_VALUE:
            return {...state, morgageEstimateValue: action.payload}    
        case SET_REAL_ESTATE_ESTIMATE:
            return {...state, realEstateEstimate: action.payload}
        case SET_MORGAGE_LENGTH:
            return {...state, morgageLength: action.payload}
        case SET_MORGAGE_STEP:
            return {...state, morgageEstimateServiceStep: action.payload, modalServiceType: 'morgage_estimate'}    
        case SET_REAL_ESTATE_VALUATION_STEP:
            return {...state,  realEstateEvaluationStep: action.payload, modalServiceType: 'real_estate_valuation'}
        case SET_SERVICE_SUBMITTING:
            return {...state, submittingServiceData: action.payload}
        case SET_MAIN_CONTACT_SUBMITTING:
            return {...state, submittingMainContactData: action.payload}
        case SET_SUBMIT_SERVICE_MESSAGE:
            return {...state, submitServiceMessage: action.payload}
        case SET_SUBMIT_CONTACT_MESSAGE:
            return {...state, submitContactMessage: action.payload}
        case GET_REALESTATE_AGENT_TEXTS:
            //Check if agent have custom common texts
            let agentCommonTexts =  _.isEmpty(action.payload[state.currentRealEstateAgent].common) ? action.payload.common : action.payload[state.currentRealEstateAgent].common;
            let newRealEstateAgentTexts = {
                common: agentCommonTexts,
                personal: action.payload[state.currentRealEstateAgent]
            }
            return {...state, realEstateAgentTexts: newRealEstateAgentTexts}
         case SUBMIT_REAL_ESTATE_VALUATION:
             let newOtherRealEstateInfo = {
                balkon: action.payload.balkon ? true: false,
                bazen: action.payload.bazen ? true: false, 
                developerskeProjekty: action.payload.developerske_projekty ? true: false,
                garaz: action.payload.garaz ? true: false,
                parkovaciStani: action.payload.parvkovaci_stani ? true: false,
                terasa: action.payload.terasa ? true: false,
                sklep: action.payload.sklep ? true: false,
                customerMessage: action.payload.popis === '' || !action.payload.popis ? 'Zákazník nenapsal vlastní popis nemovitosti.': action.payload.popis
             }
            return {...state, 
                dispozice: action.payload.dispozice,
                druh_nemovitosti: action.payload.druh_nemovitosti,
                stav_nemovitosti:action.payload.stav_nemovitosti,
                typ_vlastnictvi: action.payload.typ_vlastnictvi,
                velikost: action.payload.velikost,
                otherRealEstateInfo: newOtherRealEstateInfo
            }
        
        case GET_REALESTATE_DATA:
            return {...state, realEstateList: action.payload}
        case SET_REAL_ESTATE_LOADING_ERROR:
            return {...state, realEstateLoadingError: action.payload}

        default:
            return state;
    }
};
export default appUIReducer;